import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, PRIMARY_OUTLET, Params, Router } from '@angular/router';
import { filter, map } from 'rxjs';

import { MenuItem, MenuItemCommandEvent } from 'primeng/api';

import { BusEvent, EventBus } from 'framework';

import { NavigationService } from '../navigation/navigation.service';

import { SolisSessionService } from '../session/solis-session.service';

import { WebHelper } from '../../helpers/web/web.helper';

import { SessionKeys } from '../../constants/session-keys';
import { EventNames } from '../../constants/event-names';

import { BreadcrumbState } from '../navigation/navigation.models';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  breadcrumbState: BreadcrumbState = new BreadcrumbState(0, []);
  breadcrumbHistory: BreadcrumbState[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private eventBus: EventBus,
    private solisSessionService: SolisSessionService,
    private navigationService: NavigationService
  ) {}

  initialize(): void {
    this.loadBreadcrumbHistory();

    // NavigationStart Event fired. Record the incoming navigationId on the breadcrumbState and load previous data if popstate
    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe((event) => {
      this.loadBreadcrumbStateAndHistoryFromNavigationStart(event as NavigationStart);
    });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(filter((route) => route.outlet === PRIMARY_OUTLET))
      .subscribe((route) => {
        this.updateBreadcrumbDataFromRoute(route.snapshot.data['breadcrumb'], route.snapshot.queryParams);
      });
  }

  refreshBreadcrumbs(updateDisabledBreadcrumb: boolean = true) {
    if (updateDisabledBreadcrumb) {
      this.disableLastBreadcrumb();
    }
    this.eventBus.emit(new BusEvent<BreadcrumbState>(EventNames.websiteBreadcrumbsChanged, this.breadcrumbState));
  }

  saveBreadcrumbHistory() {
    // Save to session in case of backward/forward popstate or page reload (user refresh, etc.)
    // If we already have a history record tied to the current navigationId don't add a new one update the existing one
    const index = this.breadcrumbHistory.findIndex((x) => x.navigationId === this.breadcrumbState.navigationId);
    if (index >= 0) {
      this.breadcrumbHistory[index].breadcrumbItems = [...this.breadcrumbState.breadcrumbItems];
      this.breadcrumbHistory[index].home = this.breadcrumbState.home;
    } else {
      this.breadcrumbHistory.push(
        new BreadcrumbState(this.breadcrumbState.navigationId, [...this.breadcrumbState.breadcrumbItems], this.breadcrumbState.home)
      );
    }
    this.solisSessionService.localSession.set(SessionKeys.breadcrumbs, this.breadcrumbHistory);
  }

  // Add a fully defined breadcrumb item
  // By calling this directly you can add a MenuItem that does not contain routing information or has a specific id for use with removeBreadcrumbItems
  addBreadcrumbItem(breadcrumbItem: MenuItem, disableBreadcrumb: boolean = true): void {
    // Restricting duplicate values from entry into breadcrumbItems
    let currentRouteLength = this.breadcrumbState.breadcrumbItems.filter((breadcrumb) => breadcrumb.label === breadcrumbItem.label);

    if (currentRouteLength.length === 0) {
      breadcrumbItem.id = breadcrumbItem.id ?? breadcrumbItem.label;
      this.breadcrumbState.breadcrumbItems.push(breadcrumbItem);
    }

    this.refreshBreadcrumbs(disableBreadcrumb);

    // If this is a Navigation Breadcrumb being added then add to the history
    if (breadcrumbItem.routerLink) {
      this.saveBreadcrumbHistory();
    }
  }

  disableLastBreadcrumb() {
    if (this.breadcrumbState.breadcrumbItems?.length > 0) {
      if (this.breadcrumbState.home) {
        this.breadcrumbState.home.disabled = undefined;
      }
      this.breadcrumbState.breadcrumbItems.forEach((x) => (x.disabled = undefined));
      this.breadcrumbState.breadcrumbItems[this.breadcrumbState.breadcrumbItems.length - 1].disabled = true;
    } else if (this.breadcrumbState.home) {
      this.breadcrumbState.home.disabled = true;
    }
  }

  updateBreadcrumbCommand(breadcrumbLabel: string, command: (event: MenuItemCommandEvent) => void): void {
    let existingBreadcrumb = this.breadcrumbState.breadcrumbItems.find((breadcrumb) => breadcrumb.label === breadcrumbLabel);
    if (existingBreadcrumb) {
      existingBreadcrumb.command = command;
      this.refreshBreadcrumbs();
    }
  }

  // Add a new breadcrumb to the list with a navigation element and assign a new id
  addNavigationBreadcrumbItem(label: string, route: string, queryParams: Params = {}): void {
    const breadcrumbItem: MenuItem = { id: label, label: label, routerLink: route, queryParams, routerLinkActiveOptions: { exact: true } };
    this.addBreadcrumbItem(breadcrumbItem);
  }

  removeBreadcrumbItems(id: string, exclusive: boolean = false): void {
    const breadcrumbItem = this.breadcrumbState.breadcrumbItems.find((i) => i.id === id)!;
    const indexOfItem = this.breadcrumbState.breadcrumbItems.indexOf(breadcrumbItem);

    if (indexOfItem >= 0) {
      // Remove this item if not exclusive call and all items after this item
      this.breadcrumbState.breadcrumbItems.length = indexOfItem + (exclusive ? 1 : 0);
      this.refreshBreadcrumbs();
    }
  }

  // If there is a breadcrumb history then load the latest entry into the current state and load history from session
  private loadBreadcrumbHistory(): void {
    const sessionBreadcrumbHistory = this.solisSessionService.localSession.get<BreadcrumbState[]>(SessionKeys.breadcrumbs);
    if (sessionBreadcrumbHistory) {
      this.breadcrumbHistory = sessionBreadcrumbHistory;
      if (sessionBreadcrumbHistory.length > 0) {
        const breadcrumbSessionState = sessionBreadcrumbHistory[sessionBreadcrumbHistory.length - 1];
        this.breadcrumbState.navigationId = breadcrumbSessionState.navigationId;
        this.breadcrumbState.breadcrumbItems = [...breadcrumbSessionState.breadcrumbItems];
        this.breadcrumbState.home = breadcrumbSessionState.home;
      }
    }
  }

  private loadBreadcrumbStateAndHistoryFromNavigationStart(navigationEvent: NavigationStart): void {
    // If the trigger event is popstate it means the navigation was triggered by the browser forward/backward buttons or the location.back/forward
    // Reload a set of breadcrumbs from a previous navigation id
    if (navigationEvent.restoredState && this.breadcrumbHistory.length > 0) {
      // A popstate always comes with a restoredState property pointing to the original id we are re-loading
      // There are some difficulties with this part after a page refresh where the incoming navigationId's from the angular router are reset to 0
      const index = this.breadcrumbHistory.findIndex((x) => x.navigationId === navigationEvent.restoredState?.navigationId);
      if (index >= 0) {
        this.breadcrumbState.breadcrumbItems = [...this.breadcrumbHistory[index].breadcrumbItems];
        this.breadcrumbState.home = this.breadcrumbHistory[index].home;
      }
    }
    this.breadcrumbState.navigationId = navigationEvent.id;
    this.saveBreadcrumbHistory();
  }

  private updateBreadcrumbDataFromRoute(breadcrumbData: any, queryParams: Params = {}): void {
    if (!breadcrumbData) {
      this.breadcrumbState.breadcrumbItems = [];
      this.saveBreadcrumbHistory();
      this.refreshBreadcrumbs();
      return;
    }
    const lastItem = this.breadcrumbState.breadcrumbItems[this.breadcrumbState.breadcrumbItems.length - 1];
    // If queryParams are retrieved correctly, no longer a need for location.search to be appended to route.
    const currentRoute = `${this.getLocation().pathname}`;
    const label = breadcrumbData.label as string;

    // If the last item is the same route as the current one, this is a reload or back Navigation
    // scenario that would cause a duplicate. If that's the case, just move on
    if (lastItem && lastItem.routerLink === currentRoute && JSON.stringify(lastItem.queryParams) === JSON.stringify(queryParams)) {
      this.refreshBreadcrumbs();
      return;
    }

    const homeRoute = '/' + this.navigationService.getHomePath();
    const navigatingHome = currentRoute === homeRoute;

    // If the breadcrumb route specifies it is a top level breadcrumb or we are at the homepage we clear the list
    if (breadcrumbData.topLevelNavigation || navigatingHome) {
      this.breadcrumbState.breadcrumbItems = [];
    } else {
      //When a navigation happens we need to remove breadcrumbs that have no navigation info as they will not function on a different page
      this.breadcrumbState.breadcrumbItems = this.breadcrumbState.breadcrumbItems.filter((x) => x.routerLink);

      // If we are reloading a breadcrumb already present in the list simply cut off the list at that point
      // This also cuts off the last item which will be re-added as we finish
      this.breadcrumbState.breadcrumbItems.forEach((breadcrumbdata, index) => {
        if (breadcrumbdata.label === breadcrumbData.label && index < this.breadcrumbState.breadcrumbItems.length - 1) {
          this.breadcrumbState.breadcrumbItems.length = index;
          return;
        }
      });
    }

    // If the breadcrumb is listed to show the home button or not specified then add it to the beginning of the list if empty
    if (breadcrumbData.showHome === false) {
      this.breadcrumbState.home = undefined;
    } else if (this.breadcrumbState.home?.routerLink !== homeRoute) {
      this.breadcrumbState.home = {
        id: WebHelper.generateUuid(),
        icon: 'fa-regular fa-house',
        routerLink: homeRoute,
        routerLinkActiveOptions: { exact: true }
      } as MenuItem;
    }

    // If the current route is the home route do not need to progress to adding breadcrumbs
    if (navigatingHome) {
      this.refreshBreadcrumbs();
      return;
    }

    // Create a breadcrumb entry and fire event indicating entry is available
    this.addNavigationBreadcrumbItem(label, currentRoute, queryParams);
  }

  clearBreadCrumbItems(): void {
    this.breadcrumbState.breadcrumbItems = [];
  }

  private getLocation() {
    return window.location;
  }
}
