import { MenuItem } from 'primeng/api';

export class BreadcrumbState {
  navigationId: number;
  breadcrumbItems: MenuItem[];
  home?: MenuItem;

  constructor(id: number, breadcrumbItems: MenuItem[], home?: MenuItem) {
    this.navigationId = id;
    this.breadcrumbItems = breadcrumbItems;
    this.home = home;
  }
}
